import { Link } from 'gatsby';
import React, { ReactNode } from 'react';

import SEO from '../../SEO';
import Global from '../../page-components/Global';

import CiteThis from '../../components/CiteThis';

import { smartypants } from '../../lib/utils';

interface LessonTemplateProps {
  children    : ReactNode;
  pageContext : {
    frontmatter : {
      date        : string;
      description : string;
      title       : string;
    }
  }
}

export default function LessonTemplate (props: LessonTemplateProps): JSX.Element {
  const { children, pageContext } = props;

  const { date, description, title } = pageContext.frontmatter;

  return (
    <Global>
      <SEO
        title={smartypants(title)}
        description={smartypants(description)}
      />

      <div className="lesson-container">
        <div className="lesson-content">
          <Link to="/resources/lessons">
            &lsaquo; Lessons
          </Link>

          <h1>{smartypants(title)}</h1>

          {children}
        </div>

        <CiteThis
          title={smartypants(title)}
          date={date}
        />
      </div>
    </Global>
  );
}
